.about {
  .content-container {
    margin: 0 auto;
    max-width: 950px;
    padding: 0 0 80px 0;
  }

  .logo-section {
    padding-top: 60px;

    .logo {
      padding: 10px 15px;
    }

    .logo-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 10px;
      max-width: 1250px;
      margin: 0 auto;
    }
  }

  & em {
    color: #01a0b6;
    font-style: normal;
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    .content-container {
      padding: 0 60px 80px 60px;
    }
    .logo-section {
      padding-top: 30px;
    }
    .logo-container {
      flex-wrap: wrap;
      max-width: 600px !important;
    }
  }
  @media (max-width: 650px) {
    .content-container {
      padding: 0 20px 20px 20px;
    }
    .logo-container {
      max-width: 400px !important;
    }
    .card-container {
      display: block;

      .card {
        margin: 30px 0 0 0;
      }
    }
  }
}
