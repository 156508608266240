.timer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & h3 {
    margin-right: auto;
  }

  .timer-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .timer-numbers {
    font-size: 3.5rem;
    font-weight: bold;

    @media (max-width: 1295px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 3.5rem;
      margin: 20px auto;
    }
  }

  .timer-numbers-solo {
    font-size: 5rem;
    font-weight: bold;

    @media (max-width: 1200px) {
      font-size: 3.5rem;
      margin: 20px auto;
    }
  }

  .timer-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
}

.session-section-dark {
  #timer-select {
    background-color: $dark-mode-dark !important;
    border-color: $dark-mode-dark !important;
  }
}

.selected-timer-sound-name {
  display: inline;

  @media (max-width: 1407px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: inline;
  }
  @media (max-width: 393px) {
    display: none;
  }
}

.selected-timer-sound-name-solo {
  display: inline;

  @media (max-width: 393px) {
    display: none;
  }
}

@media (max-width: 1200px) {
  .timer {
    .timer-header {
      display: block;
    }

    .toggle-section {
      margin-top: 5px;
    }
  }
}

@media (max-width: 1000px) {
  .timer {
    .header-secondary {
      font-size: 35px !important;
    }
  }
}

@media (max-width: 768px) {
  .timer-header {
    display: flex !important;
  }

  .toggle-section {
    margin-top: 0 !important;
  }
}
