.alert-banner {
  background-image: linear-gradient(
      to right bottom,
      rgba($color-primary, 0.85),
      rgba($color-primary-dark, 0.85)
    ),
    url("/img/backgrounds/banner.jpg");
  background-position: center;
  padding: 10px 0;
  text-align: center;

  & p {
    color: white;
    margin-bottom: 0;
    font-size: 17px;
  }

  & a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
}

.cookie-alert {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #30023d;
  color: #fff;
  padding: 5px 20px 5px 10px;
  border-radius: 4px;
  font-weight: 300;

  .cookie {
    font-size: 30px;
    margin-right: 10px;
    margin-top: 2px;
  }

  & a {
    color: #fff;
    font-weight: bold;
    margin-left: 4px;
    text-decoration: none;
  }

  .x {
    font-size: 30px;
    font-weight: 400;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    margin-left: 20px;
    margin-bottom: 3px;
  }
}

header {
  padding: 75px 0;
  background-image: linear-gradient(
    to right bottom,
    rgba($color-primary, 0.85),
    rgba($color-primary-dark, 0.85)
  );
  position: relative;

  & * {
    color: white;
  }

  & p {
    font-size: 21px;
    max-width: 525px;
    margin: 0 auto;
    padding: 10px 0;
  }

  .bgWrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;

    > div {
      height: 100% !important;

      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.link-button {
  display: inline-block;
  font-size: 22px;
  padding: 12px 22px;
  margin-top: 20px;
}

.mid-page-start-session-button {
  @media (max-width: 768px) {
    display: none;
  }
}

.banner-label {
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #aaa;
  margin-bottom: 5px;
}

.logo-banner {
  padding: 15px 0;
}

.featured-banner {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  & > * {
    margin: 15px;
  }

  .morningbrew {
    width: 200px;
    opacity: 0.6;
    filter: grayscale(100%);
  }

  .techcrunch {
    width: 200px;
    opacity: 0.6;
    filter: grayscale(100%);
  }

  .yahoonews {
    width: 200px;
    opacity: 0.6;
    filter: grayscale(100%);
  }
}

.demo-video {
  max-width: 800px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  // border: 1px solid #ddd;
}

.video-action-grid {
  display: grid;
  align-items: center;
  grid-gap: 50px;
  padding: 15px 0;

  &-left {
    // grid-template-columns: 1fr 400px;
    grid-template-columns: repeat(2, 1fr);
  }

  &-right {
    // grid-template-columns: 400px 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  & video {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    // border: 1px solid #ddd;
  }
}

.features-container {
  display: grid;
  grid-template-columns: 500px 1fr;
  align-items: center;

  & .laptop-image {
    width: 500px;
    max-width: 100%;
  }

  .item-enter {
    opacity: 0;
    display: none;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
    transition-delay: 600ms;
  }
  .item-exit {
    opacity: 1;
    display: block;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-gap: 15px;

    .feature {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 15px;
      width: 100%;
      transition: all 0.2s;
      border-radius: 4px;
      padding: 15px;
      cursor: pointer;

      & svg {
        color: #495057;
        margin-top: 2px;
        width: 30px;
      }

      & p {
        font-size: 16px;
        color: #333;
        margin-bottom: 0;
      }

      &:hover {
        background-color: #ddd;
      }
    }

    .focused {
      background-color: white !important;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      cursor: unset;
    }
  }
}

.stats-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  .stat-header {
    font-size: 40px;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  .stat-details {
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  .stat {
    text-align: center;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 4px;
    margin: 10px;
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1200px) {
      width: calc(43% - 15px);
    }

    @media (max-width: 768px) {
      margin: 6px;
      width: calc((100% - 6px) / 2.2);
      height: 100px;
      padding: 20px 0;
    }
  }
}

.landing-page-subsection-container {
  margin: 0 auto;
  width: 400px;

  @media (max-width: 768px) {
    width: 90%;
  }
}

.testimonials-grid {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 30px;

  @media (max-width: 1200px) {
    justify-content: flex-start;
  }
}

.testimonials-grid::-webkit-scrollbar {
  display: none;
}

.testimonial {
  flex: 0 0 auto;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 260px;
  margin: 0 10px;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 5%;
    height: 200px;
  }

  .divider {
    margin: 15px 0;
    height: 1px;
    width: 100%;
    background-color: #eee;
  }

  & svg {
    width: 30px;
    color: #333;
    margin-bottom: 10px;
    opacity: 0.3;
  }

  p {
    flex: 1;
  }

  .info {
    text-align: center;

    h5 {
      font-size: 17.5px;
      font-weight: bold;
      margin-bottom: 3px;
    }

    span {
      font-size: 15px;
      color: #888;
    }
  }
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;

  .step {
    text-align: center;
    padding: 0 30px;

    & img {
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }

  .active {
    background-color: rgba($color-primary-light, 0.7);

    .step-icon {
      background-color: transparent;
    }
  }
}

@media (max-width: 1200px) {
  // .waterloo {
  //   padding-top: 15px;
  // }

  .columbia {
    display: none;
  }
}

@media (max-width: 1000px) {
  .steps-grid {
    justify-items: stretch;
  }

  .demo-video {
    width: 100%;
  }

  .video-action-grid {
    grid-template-columns: repeat(auto-fill, 100%) !important;
    grid-gap: 25px;
    padding: 50px 0;

    .mobile {
      display: block;
      margin: 0 auto;
    }

    .desktop {
      display: none;
    }
  }

  .features-container {
    grid-template-columns: repeat(auto-fill, 100%) !important;
    grid-gap: 50px;
  }

  .steps-grid {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 991px) {
  .umich,
  .dartmouth {
    padding-top: 5px;
  }

  .cookie-alert {
    white-space: nowrap;
    padding: 5px 20px;
    .cookie {
      font-size: 25px;
    }
  }
}

@media (max-width: 768px) {
  header {
    padding: 50px 0 !important;

    & p {
      font-size: 16px;
      padding: 5px 0;
    }
  }

  .logo-banner {
    .banner-label {
      margin-bottom: 15px;
    }
  }

  .features-grid {
    grid-template-columns: repeat(auto-fill, 100%) !important;

    .feature {
      grid-template-columns: 0.5fr 2.5fr !important;
      grid-auto-flow: column;
      margin: 0 auto;

      & svg {
        width: 35px;
        float: right;
      }
    }
  }
}

@media (max-width: 500px) {
  .cookie-alert {
    font-size: 15px;
    border-radius: 0;
    width: 100%;
    padding: 10px;
    margin: 0px;
    z-index: 1000;
    bottom: 0;

    .cookie {
      font-size: 15px;
      margin-right: 5px;
    }

    .x {
      font-size: 23px;
      margin: 0 5px;
    }
  }
}

.hiddenTaskIconsUntilHover {
  opacity: 0;
  transition: all 0.25s;
}
