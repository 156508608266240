.page-container {
  padding: 50px 0;
}

.empty-center-container {
  margin: 50px auto;
  padding: 30px 0px;
  width: 430px;
}

.center-container {
  margin: 50px auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 400px;
}

.text-container {
  text-align: center;
  max-width: 550px;
  margin: auto;

  & p {
    // padding: 0 35px;
    font-size: 17.5px;
  }
}

.split-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  align-items: center;

  & img {
    width: 75%;
    margin: 0 auto;
  }

  & video {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  filter: blur(10px);
}

@media (max-width: 768px) {
  .split-grid {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  .text-container {
    & p {
      padding: 0 15px;
    }
  }
}
