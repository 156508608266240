.dashboard-grid {
  display: grid;
  grid-template-columns: 75% 25%;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }
}

.dashboard-info-block {
  color: #555;

  & a {
    color: #555;
    text-decoration: underline;

    &:hover {
      color: #333;
    }
  }
}

.chart-container {
  background-color: white;
  padding: 30px 30px 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  & h3 {
    text-align: center;
    padding-left: 30px;
    margin-bottom: 15px;
  }
}

.right-sidebar {
  padding: 15px;
  background-color: #eee;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }

  .friend-requests,
  .pending-requests,
  .friends {
    h3 {
      margin-bottom: 0;
    }

    .badge {
      display: inline-block;
      background-color: red;
      width: 18px;
      height: 18px;
      color: white;
      font-size: 10px;
      // font-weight: normal;
      border-radius: 50%;
      transform: translateY(-6px);
      margin-left: 5px;
    }

    .friend-request,
    .pending-request,
    .friend {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 15px;
      padding: 10px 0;

      & img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .name {
        display: block;
        font-weight: bold;
      }

      .status {
        display: block;
        font-size: 14px;
        color: #555;
        margin: 5px 0;
      }

      .info {
        display: block;
        font-size: 12.5px;
        color: #555;
        margin: 5px 0;
      }

      .option-buttons {
        display: flex;
        justify-content: flex-end;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      &-skeleton {
        &:not(:last-child) {
          border-bottom: 1px solid #dedede;
        }
      }
    }

    .empty-friends {
      text-align: center;
      padding: 15px 40px;

      & img {
        width: 100%;
      }

      & p {
        margin-top: 15px;
      }
    }
  }

  .option-button {
    transition: all 0.2s;
    padding: 2.5px 15px;
    border-radius: 20px;
    font-size: 15px;
    border: 1px solid #555;
    color: #555;
    margin-left: 5px;
    background-color: transparent;

    &:hover {
      background-color: rgba(#555, 0.1);
    }
  }

  .unfriend-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    line-height: 10px;
    font-size: 20px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    margin-left: auto;
  }
}

.streak-section {
  .react-calendar-heatmap .color-scale-0 {
    fill: #ddd;
  }
  .react-calendar-heatmap .color-scale-1 {
    fill: #70edfe;
  }
  .react-calendar-heatmap .color-scale-2 {
    fill: #43e7fe;
  }
  .react-calendar-heatmap .color-scale-3 {
    fill: #15e1fe;
  }
  .react-calendar-heatmap .color-scale-4 {
    fill: #01c8e4;
  }
  .react-calendar-heatmap .color-scale-5 {
    fill: #01a0b6;
  }
  .react-calendar-heatmap .color-scale-6 {
    fill: #0193a7;
  }
  .react-calendar-heatmap .color-scale-7 {
    fill: #017889;
  }
  .react-calendar-heatmap .color-scale-8 {
    fill: #015d6a;
  }
  .react-calendar-heatmap .color-scale-9 {
    fill: #004751;
  }
}

// SwiperJS navigation styles

:root {
  --swiper-navigation-color: white !important;
  --swiper-navigation-size: 24px !important;
}

.swiper,
.swiper-wrapper {
  position: static !important;
}

.swiper-button-prev,
.swiper-button-next {
  background: $color-primary;
  overflow: absolute;
  font-weight: bold;
  border-radius: 50%;
}

.swiper-button-prev {
  margin-left: -24px;
  padding: 16px 18px 16px 14px;
}

.swiper-button-next {
  margin-right: -24px;
  padding: 16px 14px 16px 18px;
}

.swiper-button-disabled {
  opacity: 100% !important;
  background: #ddd;
}

// SwiperJS card styles

.swiper-cards {
  overflow: visible !important;
  max-width: 90%;
}

.swiper-slide {
  border-radius: 15px;
  overflow: hidden;
}

.swiper-slide-active {
  overflow: visible;
}

.swiper-3d {
  perspective: 1200px !important;
}

@media (max-width: 768px) {
  .swiper-3d {
    perspective: 1000px !important;
  }
}
