//width when task card expands to whole section
$expanded-screen-width: 1260px;

.tasks {
  padding-bottom: 0px;
  border-radius: 4px;
  grid-column: span 2;

  &.session-section {
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .tasks-body {
    height: 100%;
    display: flex;
    flex-direction: column;

    .tasks-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      @media (max-width: $expanded-screen-width) {
        display: flex;
      }
    }

    .tasks-grid {
      flex: 1;
      display: grid;
      grid-template-columns: minmax(350px, 1fr) 1fr;
      grid-gap: 60px;
      padding: 0 15px;
      overflow: hidden;
      //needed to manually define when grid expands to 100% so that the appearance of a scrollbar didn't make it unpredictable when expanding window from smaller size vs contracting window from bigger size
      @media (max-width: $expanded-screen-width) {
        grid-template-columns: repeat(auto-fill, 100%);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .invite-friend-container-header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      align-items: center;
      width: 100%;
      max-width: 424px;
      margin: 0 auto;
      margin-top: -8px;
    }

    .invite-friend-container {
      display: none;
      @media (max-width: $expanded-screen-width) {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        width: 100%;
      }
    }

    .invite-friend-text-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (max-width: $expanded-screen-width) {
        display: none;
      }
    }
    .invite-friend-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .demo-task-card-img-container {
      position: relative;
      max-width: 424px;
    }

    .copy-invite-link-container {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 10px;
      transform: translate(-50%, -50%);
      height: 54%;
      width: 46%;
      max-width: 120px;
      max-height: 120px;
      border: 3px solid $color-primary;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px #00000014;
      transition: all 0.2s;
    }

    .demo-task-card {
      margin: 0 auto;
      @media (max-width: $expanded-screen-width) {
        margin-top: 10px;
      }
    }

    .invite-friend-pill {
      position: absolute;
      right: 25px;
      top: 135px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      border-radius: 20px;
      height: 25px;
      width: fit-content;
      cursor: pointer;
      max-width: 50%;
      transition: all 0.2s;
      @media (max-width: "768px") {
        top: 0px;
        position: relative;
        right: auto;
        margin: 0 auto;
      }
    }
  }
}

.task-card {
  .dot-grid {
    grid-template-columns: 5fr 1px 3fr 35px !important;

    .option {
      background-color: transparent;
      border: none;
      font-size: 25px;
      color: white;
      padding: 0 12.5px;
      border-radius: 4px;

      &::after {
        display: none;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $color-primary-light;
      }
    }
  }

  &-header {
    display: grid;
    grid-template-columns: 5fr 3fr;
    align-items: center;

    grid-gap: 10px;
    background-color: $color-primary;
    padding: 7.5px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    & h3 {
      margin-bottom: 0;
      color: white;
    }

    .progress {
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid white;

      &-bar {
        background-color: white;
        color: #676767;
      }
    }

    .empty {
      .progress-bar {
        color: white;
      }
    }
  }

  &-body {
    padding: 15px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .empty-tasks {
      text-align: center;

      & img {
        max-width: 150px;
        margin-bottom: 10px;
      }

      & span {
        display: block;
        color: #707070;
      }
    }

    .task-list {
      list-style: none;
      padding-left: 0;

      .task-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;
        background-color: white;
        word-break: break-word;

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          display: none;
        }

        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: 1.95em;
          margin-bottom: 1.2rem;
          cursor: pointer;
        }

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25em;
          height: 1.25em;
          border: 1px solid #ccc;
          background: #fff;
          border-radius: 4px;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: "\2713\0020";
          position: absolute;
          top: 0.1em;
          left: 0.15em;
          font-size: 1.15em;
          line-height: 0.8;
          color: $color-primary;
          // transition: all .2s;
        }

        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }

        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
        }

        [type="checkbox"]:disabled:checked + label:after {
          color: #999;
        }

        [type="checkbox"]:disabled + label {
          color: #aaa;
        }

        .disabled::before {
          background-color: #eee !important;
        }

        label:hover:before {
          border: 1px solid $color-primary !important;
        }

        & input[type="checkbox"] {
          margin-right: 10px;
        }

        .dropdown {
          position: static;
        }

        .current-tag {
          padding: 2px 5px;
          background-color: $color-primary;
          color: white;
          border-radius: 4px;
          font-size: 10px;
          word-break: normal !important;
        }

        &-options {
          display: flex;
          flex-direction: row;
          align-items: center;

          .option {
            background-color: transparent;
            border: none;
            font-size: 25px;
            color: #aaa;
            padding: 0 12.5px;
            border-radius: 4px;

            &::after {
              display: none;
            }

            &:focus {
              outline: none;
            }

            &:hover {
              background-color: #ddd;
            }
          }
        }
      }

      .completed {
        background-color: #eee;
        border-radius: 4px;

        & span {
          text-decoration: line-through;
        }
      }

      .current {
        background-color: $color-primary-light;
        border-radius: 4px;
      }
    }
  }
}

.task-card-dark {
  color: white;

  .task-item {
    background-color: $dark-mode-light !important;

    .handle {
      color: #8a8a8a !important;

      * {
        color: #8a8a8a !important;
      }
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      background-color: $dark-mode-light !important;
      border-color: #505050 !important;
    }

    &:not(:last-child) {
      border-color: $dark-mode-lighter !important;
    }

    .option:hover {
      background-color: $dark-mode-lighter !important;
    }
  }

  .completed {
    background-color: $dark-mode-lighter !important;
  }

  .task-card-body {
    border-color: $dark-mode-lighter !important;
  }
}

.task-list.highlighted-container {
  background-color: #eee;
  border-radius: 4px;
}

.task-item.highlighted-draggable {
  background-color: $color-primary-light !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: white;
}

.task-card-dark {
  .task-list.highlighted-container {
    background-color: $dark-mode-dark;
    border-radius: 4px;
  }

  .task-item.highlighted-draggable {
    background-color: $color-primary !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: white;
  }
}

@media (max-width: 768px) {
  .tasks-grid {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }
}
