.button-dark {
  background-color: $color-primary;
  padding: 8px 15px;
  border-radius: 4px;
  color: white;
  border: 1px solid $color-primary;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
    text-decoration: none;
    color: white;
  }
}

.button-ghost-dark {
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 4px;
  color: #676767 !important;
  border: 1px solid #676767;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(#676767, 0.1);
    text-decoration: none;
    color: #676767;
  }
}

.button-ghost-light {
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 4px;
  color: white !important;
  border: 1px solid white;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(white, 0.1);
    text-decoration: none;
    color: #676767;
  }
}

.button-light {
  background-color: white;
  padding: 8px 15px;
  border-radius: 4px;
  color: #3c3c3c !important;
  border: 1px solid white;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: #eee;
    border-color: #eee;
    text-decoration: none;
    color: #3c3c3c;
  }
}

.button-lg {
  font-size: 18px;
  font-weight: bold;
}

.button-x-lg {
  padding: 10px 17.5px;
  font-size: 20px;
}

.button-sm {
  padding: 5px 12.5px;
  font-size: 14px;
}

.button-disabled {
  cursor: not-allowed !important;
  filter: grayscale(0.5);
  opacity: 0.8;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba($color-primary, 0.6);
    border-color: rgba($color-primary, 0.6);
  }
}

.button-gold {
  background-color: #e9ce3b;
  padding: 8px 15px;
  border-radius: 4px;
  color: white;
  border: 1px solid #e9ce3b;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: #d9c03c;
    border-color: #d9c03c;
    text-decoration: none;
    color: white;
  }
}

.button-black {
  background-color: #333;
  padding: 8px 15px;
  border-radius: 4px;
  color: white;
  border: 1px solid #333;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: #2c2c2c;
    border-color: #2c2c2c;
    text-decoration: none;
    color: white;
  }
}

.button-dark-mode {
  background-color: $dark-mode-light;
  padding: 8px 13px;
  border-radius: 4px;
  color: white;
  border: 1px solid $dark-mode-light;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: $dark-mode-lighter;
    border-color: $dark-mode-lighter;
    text-decoration: none;
    color: white;
  }

  &-dark {
    background-color: $dark-mode-lighter;
    border: 1px solid $dark-mode-lighter;
  }
}

.button-red {
  background-color: #dc3545;
  padding: 8px 15px;
  border-radius: 4px;
  color: white;
  border: 1px solid #dc3545;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    background-color: #c82333;
    border-color: #c82333;
    text-decoration: none;
    color: white;
  }
}

.oauth-button {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  border-radius: 4px;
  padding: 2px;
  width: 100%;
  border: none;
  color: white;
  transition: all 0.2s;

  & span {
    background-color: white;
    border-radius: 2.5px;
    padding: 3px 10px 5px 10px;
    text-align: center;

    & img {
      height: 20px;
      width: 20px;
    }
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.google-button {
  background-color: #4285f4;

  &:hover {
    background-color: #2774f4;
  }
}

.facebook-button {
  background-color: #3b5998;
  color: white !important;

  &:hover {
    background-color: #2f487d;
  }
}

.discord-button {
  background-color: #7289da;
  border-color: #7289da;
  color: white !important;

  & img {
    width: 25px !important;
    height: 25px !important;
    transform: translate(-3px, 2px);
  }

  &:hover {
    background-color: #6177f4;
    border-color: #6177f4;
  }
}

.email-button {
  background-color: #6b7282;
  color: white !important;

  &:hover {
    background-color: #5c626f;
  }
}

.auth-modal-close-button {
  width: 16px;
  height: 16px;
  color: "inherit";
}

.auth-modal-close-button-expanded {
  width: 16px;
  height: 16px;
  color: #fff;

  @media (max-width: 996px) {
    color: inherit;
  }
}
