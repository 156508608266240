$color-primary: #01a0b6;
$color-primary-light: #ebf8fa;
$color-primary-dark: #015d6a;

$dark-mode-dark: #17171d;
$dark-mode-light: #252429;
$dark-mode-lighter: #35343c;

body {
  background-color: #f7f7f7;
}

section {
  padding: 65px 0;

  @media (max-width: 768px) {
    padding: 35px 0;
  }
}

::selection {
  background: rgba($color-primary-light, 0.5) !important;
}

a {
  cursor: pointer;
}

.mobile {
  display: none;
}

.black-text-important {
  color: black !important;
}

@media (max-width: 768px) {
  .navbar {
    padding: 5px 0px !important;
  }
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

.container {
  padding-left: 17.5px !important;
  padding-right: 17.5px !important;

  @media (max-width: 768px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.primary-color-text {
  color: $color-primary;
}

.rounded {
  border-radius: 15px;
}

.media-page {
  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Roboto Slab;
  }
}

.header-slash {
  width: 33%;
  height: 11px;
  background-color: $color-primary;
  border-radius: 10px;
  margin-bottom: 10px;
}

.side-tag {
  display: inline-block;
  background-color: $color-primary;
  padding: 5px 7.5px;
  border-radius: 4px;
  font-size: 12.5px;
  font-weight: normal;
  color: white;
  transform: translateY(-4px);
  margin-left: 5px;
}

.confirmation-modal {
  background-color: white;
  padding: 30px;
  border-radius: 4px;

  &-overlay {
    // animation-name: react-confirm-alert-fadeIn;
    animation: react-confirm-alert-fadeIn 0.2s 0s forwards !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }

  &-dark {
    &::-webkit-scrollbar-track {
      background-color: $dark-mode-dark;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-mode-lighter;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba($dark-mode-lighter, 0.7);
    }
  }
}

.highlight {
  background-image: linear-gradient(to right, rgba($color-primary-light, 0.7), rgba($color-primary, 0.7));
  border-radius: 4px;
  padding: 0px 2.5px;
}

.solid-highlight {
  line-height: 1.4 !important;
  span {
    background: $color-primary;
    border-radius: 4px;
    padding: 3px 8px;
    color: white;
  }
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $color-primary;
}

.user-picture {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.user-badge {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  background-color: $color-primary-dark;
  border-radius: 50%;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }
}

.editableHover {
  &:hover {
    opacity: 0.5;
    transition: opacity 0.15s;
    cursor: pointer;
  }
}

.damageAnimation {
  animation: damageAnimation 0.5s 0s forwards;
}

@keyframes damageAnimation {
  0%,
  50% {
    transform: translateX(0);
  }
  10%,
  60% {
    transform: translateX(5px);
  }
  20%,
  75% {
    transform: translateX(-5px);
  }
  35%,
  90% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.successAnimation {
  animation: successAnimation 0.5s 0s forwards;
}

@keyframes successAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
