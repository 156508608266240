.header-primary {
  font-size: 60px;
  font-weight: bold;
}

.header-secondary {
  font-size: 50px;
  font-weight: bold;
}

.header-tertiary {
  font-size: 40px;
  font-weight: bold;
}

.header-fourth {
  font-size: 30px;
  font-weight: bold;
}

.header-fifth {
  font-size: 25px;
  font-weight: bold;
}

.header-sixth {
  font-size: 20px;
  font-weight: bold;
}

.header-seventh {
  font-size: 17.5px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .header-primary {
    font-size: 40px !important;
  }

  .header-secondary {
    font-size: 40px !important;
  }

  .header-tertiary {
    font-size: 35px !important;
  }

  .header-fourth {
    font-size: 25px !important;
  }

  .header-fifth {
    font-size: 22.5px !important;
  }

  .header-sixth {
    font-size: 20px !important;
  }

  .header-seventh {
    font-size: 17.5px !important;
  }
}
