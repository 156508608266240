.spotify-wrapper {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &.inactive {
    display: none;
  }

  .button-dark {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
  }
}

@media (max-width: 768px) {
  .spotify-wrapper {
    width: 100%;
    height: 35%;
    bottom: 0px;
    right: 0px;
    border-radius: 0px;
    // fixed to prevent the wrapper from not scrolling along with the page when in full mobile view
    // the absolutely positioned button is still positioned relative to this fixed wrapper
    position: fixed;
  }
}
