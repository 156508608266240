body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  color: #171717;
}

body::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

::-moz-selection {
  background-color: rgba(112, 199, 211, 0.3);
}

::selection {
  background-color: rgba(112, 199, 211, 0.3);
}

.noX {
  overflow-x: hidden;
}

.noselect {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input {
  font-family: "Inter", sans-serif;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.checkoutFormInput,
.StripeElement {
  font-family: "Inter", sans-serif !important;
  display: block;
  margin: 4px 0 8px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  width: 100%;
  min-width: 150px;
  background-color: white;
}

.checkoutFormLabel {
  font-weight: 500;
  color: white;
}

#root {
  min-height: 100vh;
}

code {
  color: #70c7d3;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 4px 3px;
  border-radius: 2px;
  font-size: 1.1rem;
}

p {
  margin: 0;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

.c {
  align-items: center;
  justify-content: center;
}

.spa {
  justify-content: space-around;
  align-items: center;
}

.spb {
  justify-content: space-between;
  align-items: center;
}

.frs {
  justify-content: flex-start;
  align-items: center;
}

.fre {
  justify-content: flex-end;
  align-items: center;
}

.fcs {
  justify-content: center;
  align-items: flex-start;
}

.fce {
  justify-content: center;
  align-items: flex-end;
}

.fw {
  flex-wrap: wrap;
}

.aiC {
  align-items: center;
}

.aiFS {
  align-items: flex-start;
}

.aiFE {
  align-items: flex-end;
}

.aiBL {
  align-items: baseline;
}

.aiSTR {
  align-items: stretch;
}

.jcC {
  justify-content: center;
}

.jcFS {
  justify-content: flex-start;
}

.jcFE {
  justify-content: flex-end;
}

.jcSPB {
  justify-content: space-between;
}

.jcSPA {
  justify-content: space-around;
}

.jcSPE {
  justify-content: space-evenly;
}

.div100 {
  width: 100%;
  align-self: center;
  margin: 10px 0 !important;
}

.muiCapitals {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.h100 {
  height: 100%;
}

.w90 {
  width: 90%;
}

.wA {
  width: auto;
}

.hA {
  height: auto;
}

.hFC {
  height: fit-content;
}

.wFC {
  width: fit-content;
}

.loadingPlaceholder {
  height: 5px;
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
}

.tac {
  text-align: center;
}

.xs {
  font-size: 10px !important;
}

.sm {
  font-size: 13px !important;
}

.md {
  font-size: 15px !important;
}

.lg {
  font-size: 18px !important;
}

.xl {
  font-size: 21px !important;
}

.xxl {
  font-size: 25px !important;
}

.xxxl {
  font-size: 30px !important;
}

.xxxxl {
  font-size: 36px !important;
}

.lowerCase {
  text-transform: lowercase;
}

.upperCase {
  text-transform: uppercase;
}

.xl62 {
  font-size: 62px !important;
}

.light {
  font-weight: 100;
  opacity: 0.6;
}

.thin {
  font-weight: 300;
}

.bold {
  font-weight: bold !important;
}

.textShadow {
  text-shadow: 1px 1px 5px #393939;
}

.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.mB30 {
  margin-bottom: 30px !important;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB15 {
  margin-bottom: 15px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mT30 {
  margin-top: 30px !important;
}

.mT20 {
  margin-top: 20px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.mT5 {
  margin-top: 5px !important;
}

.mR5 {
  margin-right: 5px !important;
}

.mR10 {
  margin-right: 10px !important;
}

.mR15 {
  margin-right: 15px !important;
}

.mR20 {
  margin-right: 20px !important;
}

.mR40 {
  margin-right: 40px !important;
}

.mL5 {
  margin-left: 5px !important;
}

.mL10 {
  margin-left: 10px !important;
}

.mL15 {
  margin-left: 15px !important;
}

.mL20 {
  margin-left: 20px !important;
}

.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mH10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mH20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.pad5 {
  padding: 5px;
}

.pad10 {
  padding: 10px;
}

.pad20 {
  padding: 20px;
}

.pad1020 {
  padding: 10px 20px;
}

.padH10 {
  padding-left: 10px;
  padding-right: 10px;
}

.bsbx {
  box-sizing: border-box;
}

.noDeco {
  text-decoration: none;
}

.clickableIcon {
  color: #393939;
  opacity: 0.7;
  transition: 0.25s;
}

.clickableIcon:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.1);
}

.clickableIcon:active {
  transform: scale(0.95);
  transition: 0.05s;
}

.clickableIcon p:hover {
  cursor: pointer;
}

.clickableText,
.clickableTextRed {
  color: #393939;
  opacity: 0.8;
  transition: 0.25s;
}

.clickableText:hover {
  cursor: pointer;
  color: #01a0b6;
  opacity: 1;
}

.clickableText p:hover {
  cursor: pointer;
}

.clickableText:active,
.clickableTextRed:active {
  transform: scale(0.95);
  transition: 0.05s;
}

.clickableTextRed:hover {
  cursor: pointer;
  color: #c45842;
  opacity: 1;
}

.clickableTextRed:active {
  transform: scale(0.95);
  transition: 0.05s;
}

.greenBg {
  background-color: #01a0b6 !important;
}

.redBg {
  background-color: red !important;
}

.lightGreenBg {
  background-color: rgba(77, 185, 200, 0.5) !important;
}

.grayBg {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.blackBg {
  background-color: #171717 !important;
}

.whiteBg {
  background-color: white;
}

.green {
  color: #01a0b6 !important;
}

.red {
  color: #c45842 !important;
}

.gray {
  color: rgba(0, 0, 0, 0.4);
}

.wht {
  color: white !important;
}

.black {
  color: #171717 !important;
}

.iconLeft {
  margin-right: 12px;
}

.opac3 {
  opacity: 0.3 !important;
}

.opac7 {
  opacity: 0.7 !important;
}

.opac9 {
  opacity: 0.9 !important;
}

.hideScrollBar {
  -ms-overflow-style: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.hover:hover {
  cursor: pointer;
}

.hover p:hover {
  cursor: pointer;
}

.infoTile {
  min-width: 250px;
  max-width: 450px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  max-height: 275px;
  border-radius: 10px !important;
}

.infoTile:hover {
  cursor: pointer !important;
  transform: scale(1.02) !important;
}

.infoTile div:hover,
.infoTile p:hover {
  cursor: pointer;
}

.infoOverlap {
  background-color: rgba(96, 96, 96, 0.7);
  width: 100%;
  margin: -2px 0 !important;
  display: flex;
  flex-direction: column;
  height: 101%;
  transition: 0.25s;
}

.infoOverlap:hover {
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.infoOverlap p {
  color: white;
}

.infoBoxHeader,
.infoBoxFooter {
  align-self: flex-start;
}

.infoBoxHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.9;
  padding: 4px 10px;
  box-sizing: border-box;
}

.infoBoxMainContent {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 150px;
  box-sizing: border-box;
}

.infoBoxText {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.infoBoxFooter {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.unitEventPaper {
  padding: 10px;
  transition: 0.3s;
  margin: 10px 0;
  max-width: 100%;
}

.unitEventPaper:hover {
  cursor: pointer;
  background-color: #01a0b6;
}

.unitEventPaper:hover div {
  cursor: pointer;
}

.unitEventPaper:hover p {
  cursor: pointer;
  color: white;
  transition: 0.3s;
}

.snackBarContent {
  background-color: #01a0b6 !important;
}

.snackBarContentError {
  background-color: #c45842 !important;
}

.snackBarMessage {
  font-size: 17px;
}

.snackBarIcon {
  margin-right: 10px;
}

.error {
  border: #484a46 2px solid;
  padding: 10px;
  color: #fef6e2;
  font-size: 20px;
  width: 300px;
  border-radius: 10px;
  margin: 0 auto 20px;
  background-color: red;
}

.warningBox {
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
  margin: 10px auto;
  background-color: rgba(255, 0, 0, 0.1);
}

.warningBox > p {
  font-size: 10px;
  color: red;
}

.hvr:hover {
  cursor: pointer;
}

.newsList {
  min-height: 200px;
  margin: 4px;
  overflow: hidden;
  padding: 15px;
}

.newsList div {
  border-radius: 5px;
  overflow: hidden;
}

.newsImage {
  border-radius: 8px;
}

.newsImage:hover {
  transform: scale(1.05);
  cursor: pointer;
  border: 3px solid #c45842;
}

.newsImage:active {
  transform: scale(0.95);
}

.daysInput {
  margin: 0 10px !important;
  width: 30px !important;
}

.amountPercentInput {
  width: 70px;
}

.allowedUses {
  border-left: 1px solid rgb(215, 215, 215);
  border-right: 1px solid rgb(215, 215, 215);
  padding: 0 10px;
}

.teacherListBox {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 15px;
  margin: 10px;
  min-width: 330px;
}

.teacherListBox:hover {
  cursor: pointer;
  border: 1px solid #01a0b6;
  background-color: rgba(250, 250, 250);
}

.teacherListBox:hover p {
  color: #01a0b6;
}

.teacherListBox p:hover {
  cursor: pointer;
}

.dataBox {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: rgba(77, 185, 200, 0.15);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.subjectColorPick:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.adminSubjectForm {
  width: 100%;
  max-width: 700px;
  min-width: 400px;
}

.adminSubjectBox > div {
  padding: 5px 15px 3px;
  border-radius: 20px;
  margin: 5px auto;
}

.adminSubjectBox > div:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.adminSubjectBox > div > p:hover {
  cursor: pointer;
}

.multiStreamerImage {
  margin-left: -25px;
}

.profilePic {
  border-radius: 200px;
  object-fit: cover;
  background: #e2e2e2;
}

.extra-info-form {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 0 20px;
  padding: 20px;
  overflow: auto;
}

.userSelectBtn {
  margin: 20px;
  border: 1px solid #01a0b6;
  padding: 10px 20px;
  border-radius: 10px;
  color: #01a0b6;
}

.userSelectBtn:hover {
  color: white;
  background-color: rgba(77, 185, 200, 0.7);
  cursor: pointer;
}

.imageToUpload {
  /* border-radius: 75px; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.pilotBtn {
  background-color: #f77118;
  border-radius: 30px;
  margin-bottom: 4px;
}

.pilotBtn.reg {
  padding: 10px 24px;
}

.pilotBtn.sm {
  padding: 6px 14px;
}

.pilotBtn.xs {
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.pilotBtn.reg > p {
  line-height: 20px !important;
}

.pilotBtn.sm > p {
  line-height: 20px !important;
}

.pilotBtn.xs > p {
  line-height: 12px !important;
}

.pilotBtn:hover {
  cursor: pointer;
  border-bottom: 4px solid #b05112;
  margin-bottom: 0;
}

.pilotBtn > p:hover {
  cursor: pointer;
}

.pilotBtn:active {
  margin-top: 4px;
  margin-bottom: 0;
  border: none;
  transform: scale(0.97);
}

.pilotBlueCentralLogo {
  margin: 15px auto;
  width: 200px;
}

.pilotInput {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.pilotInput.familyName,
.pilotInput.givenName {
  width: 100%;
}

.pilotInput.familyName {
  margin-right: 5px;
}

.pilotInput.givenName {
  margin-left: 5px;
}

.pilotInput.state {
  width: 225px;
  margin-left: 10px;
}

.pilotFormPopUp {
  width: 100%;
}

.pilotFormPopUp > div {
  width: 100%;
}

.pilotFormPopUp > div > div {
  width: 100%;
}

.numOfStudents {
  border: none;
  width: 54px;
  text-align: right;
  font-size: 24px;
  line-height: 5px;
  border-bottom: 1px solid lightgray;
}

.purchasePilotHeader > div > img {
  width: 60px;
  height: auto;
  margin-right: 15px;
}

.pilotPurchaseBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.cartHeader {
  padding: 20px 20px 0;
}

.thankYouReceipt {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-width: 300px;
}

.classCreationOverview {
  width: 90%;
  max-width: 470px;
  margin: 30px auto 10px;
}

.classCreationOverview ol > li {
  margin-top: 10px;
  font-weight: 600;
}

.classCreationOverview ul > li {
  font-size: 14px;
}

.notificationsBadge > span:hover {
  cursor: pointer;
}

.notificationBottomNav {
  width: 100%;
}

.notificationBox {
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 15px;
  margin: 10px auto 0;
  box-sizing: border-box;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.notificationEventTitle {
  border-radius: 30px;
  padding: 0 10px;
}

.notificationEventTitle > p {
  color: white;
  line-height: 18px;
  text-shadow: 1px 1px 1px #393939;
  font-size: 15;
  opacity: 0.9;
}

.notificationBox:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.075);
  opacity: 1 !important;
}

.SalesAccount {
  border-radius: 25px;
  /* box-shadow: 1px 1px 20px rgba(0,0,0,0.1); */
  padding: 20px;
  box-sizing: border-box;
  margin: 20px auto;
  max-width: 500px;
}

.mailSignupIconBox {
  background: gray;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.mailSignupIconBox:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.mailSignupIconBox:active {
  transform: scale(0.95);
}

.socialIcon {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.socialIcon:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.socialIcon:active {
  transform: scale(0.95);
}

#sneakPeakVideo {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

.testimonialsContainer {
  width: 100%;
  max-width: 1000px;
  padding: 20px 80px;
}

.testimonialsContainer .slider-list {
  height: 225px;
}

.testimonialContainer img {
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.testimonialContainer {
  padding: 0 65px;
}

.ssHero {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 64px 8px 0 8px;
  justify-content: center;
  text-align: center;
}

.ssHero img {
  height: 100px;
  width: 100px;
  flex-shrink: 0;
}

.ssHero span {
  color: #01a0b6;
}

.heroSpacer {
  margin-top: 32px;
}

.ssPaper-container-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ssPaper {
  width: 100%;
  padding: 40px;
  border: 1px solid #45a4b0;
}

.ssPrice:hover {
  cursor: pointer;
}

.ssPrice p:hover {
  cursor: pointer;
}

.ssBullet > p {
  font-size: 14px;
  font-weight: 100;
}

.ssBullet svg {
  font-size: 21px;
}

.ssBullets {
  padding: 0 20px;
  box-sizing: border-box;
}

.ssTestimonials {
  width: 100%;
}

.ssTestimonialImgContainer > img {
  border-radius: 75px;
  height: 150px;
  margin: 25px auto;
}

.planTitle {
  margin: 0;
  font-size: 1.5rem;
}

.paymentCaption {
  font-size: 0.9rem;
  color: #393939;
  opacity: 0.7;
}

.teacherCaption {
  font-size: 0.9rem;
  color: #393939;
  opacity: 0.7;
  font-weight: 600;
  text-align: right;
  margin-bottom: 8px;
}

.teacherCaption:hover {
  opacity: 0.9;
}

.teacherCaption > svg {
  font-size: 1rem;
  vertical-align: middle;
  margin-right: 4px;
  opacity: 0.7;
}

/* Braintree & Payment Form styles */
.hosted-field {
  height: 50px;
  box-sizing: border-box;
  padding: 12px;
  /* display: inline-block; */
  box-shadow: 0px 1px 4px rgba(57, 57, 57, 0.15);
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid rgba(57, 57, 57, 0.35);
  line-height: 20px;
  background: #fff;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 300ms ease-in-out;
}

#card-number-label,
#card-details-container label,
#promo-code-container label {
  font-weight: bold;
  font-size: 0.75rem;
  color: #393939;
  opacity: 0.8;
  margin-top: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  display: inline-block;
}

#promo-code-input {
  border: none;
  font-size: 16px;
  font-family: courier, monospace;
  font-weight: lighter;
  color: #393939;
}

#promo-code-input::placeholder {
  color: rgb(204, 204, 204);
}

#card-number-label {
  margin-bottom: 0 !important;
  margin-top: 0;
}

#visa,
#mastercard,
#amex {
  width: 50px;
  height: 30px;
  margin-left: 3px;
  margin-right: 3px;
}

#card-number-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
  align-items: flex-end;
}

#valid-card-images-container {
  display: flex;
  flex-direction: row;
}

#card-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.freeTrialText {
  font-size: 22px;
  font-weight: 600;
  opacity: 0.7;
  line-height: 0;
  margin-bottom: 20px;
}

.salesHeader {
  font-size: 40px;
  line-height: 35px;
}

.salesHeader.shrink {
  font-size: 24px;
  line-height: 18px;
  transition: 0.3s;
}

.salesSubheader {
  font-size: 18px;
}

.salesSubheader.shrink {
  font-size: 12px;
  transition: 0.3s;
}

.salesLogo {
  height: auto;
  width: 115px;
  transition: 0.3s;
}

.salesLogo.shrink {
  height: auto;
  width: 60px;
}

.salesHeaderDivider {
  margin-top: 20px !important;
}

.salesHeaderDivider.shrink {
  margin-top: 5px !important;
  transition: 0.3s;
}

.salesSticker {
  height: auto;
  width: 95px;
}

.rowSticker {
  max-width: 100%;
  max-height: 200px;
}

.salesFooter {
  margin: 15px auto;
}

.salesFooter > p {
  margin: 5px auto;
}

.salesFooter a {
  color: #01a0b6;
}

.salesTypeSelector {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #01a0b6;
  padding: 5px 10px;
  border-radius: 0 0 0 10px;
}

.sponsorFormImage {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 15px;
}

.sponsorDisplayBox {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 90%;
  max-width: 450px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px white;
}

.Teach {
  background-color: white;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.BodyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 70px;
  overflow: hidden;
}

.nav-container {
  background-color: #01a0b6;
  width: 70px;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
}

.nav-logo {
  width: 40px;
  height: 40px;
  align-self: center;
  margin: 20px 0 15px;
}

.teachNavHeader {
  color: rgba(255, 255, 255, 0.85);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  height: 30vh;
}

.splitLineText {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.splitLineText:before,
.splitLineText:after {
  content: "";
  border-top: 1px solid;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.splitLineText:after {
  margin: 0 0 0 20px;
}

.active {
  color: white;
  text-shadow: 1px 1px 3px gray;
}

.inactive {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.inactive:hover {
  color: white;
}

.pActive {
  border: 1px solid #393939;
  border-radius: 20px;
  cursor: default;
}

.pActive:hover {
  cursor: default;
}

.pInactive {
  opacity: 0.5;
}

.pInactive:hover {
  cursor: pointer;
  opacity: 1;
}

.pUnderline {
  height: 3px;
  background: #393939;
  border-radius: 2px;
}

.teacherEventsForm,
.eventView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 15px;
  margin: 0 auto 25px;
  max-width: 85%;
  width: 600px;
}

.eventIcon {
  color: #393939;
  opacity: 0.7;
  transition: 0.15s;
  margin-right: 10px;
}

.eventDescription p:nth-child(1) {
  margin-bottom: 5px;
}

.eventDescription p:nth-child(2) {
  margin-bottom: 15px;
}

.topicsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
}

.topicChip {
  margin: 3px;
}

.topicSelect {
  margin: 3px;
  font-weight: 400;
  transition: 0.15s;
  color: #393939;
}

.topicSelect:hover {
  cursor: pointer;
  color: #01a0b6;
}

.newTopicInput {
  max-width: 100%;
  width: 300px;
}

.availableTopics {
  border-right: 1px solid rgba(96, 96, 96, 0.3);
}

.topicsContainer {
  margin: 10px auto;
}

.iconButton {
  margin: 6px;
}

.questionViewAnswers {
  width: 570px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.answer {
  margin: 10px;
}

.correct {
  color: #01a0b6;
  font-weight: bold;
}

.questionBtns {
  align-self: flex-end;
}

.questionsContainer {
  max-width: 85%;
  width: 615px;
  margin-bottom: 40px;
}

.questionsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.sortableQuestion {
  padding: 15px;
  background: lightgray;
  border-radius: 35px;
  margin: 5px;
  display: flex;
  flex-direction: row;
}

.EditQuestionOrder {
  margin-top: 25px;
}

.EditQuestionOrder ol {
  list-style: none;
  counter-reset: item;
  margin-right: 40px;
}

.EditQuestionOrder li {
  counter-increment: item;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EditQuestionOrder li:before {
  margin-right: 10px;
  content: counter(item);
  background: #01a0b6;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionFormContainer {
  margin-top: 100px;
}

.endSessionBtnBox {
  margin: 15px;
}

.endSessionBtn {
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #c45842;
  opacity: 0.7;
  margin-left: 10px;
}

.eventClosed {
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px 6px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: #c45842;
  opacity: 0.7;
}

.eventClosed:hover {
  content: attr("hdhd");
}

.endSessionBtn:hover {
  box-shadow: 1px 1px 10px #393939;
  transform: scale(1.05);
  cursor: pointer;
  opacity: 0.9;
}

.reopenEvent {
  border-radius: 5px;
  padding: 3px 6px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: #01a0b6;
  opacity: 0.6;
}

.reopenEvent:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.trfSubHead {
  align-self: flex-start;
}

.resourceType {
  width: 250px;
  margin-top: 8px !important;
}

.streamerBox:hover {
  cursor: pointer;
  background-color: #01a0b6;
}

.streamerBox:hover p {
  color: white;
  cursor: pointer;
}

.StreamerQuestionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;
}

.questionInput {
  width: 500px;
  max-width: 90%;
}

.questionFormBtns {
  margin: 20px;
  align-self: center;
}

.questionFormPaper {
  padding: 15px 15px 30px 15px;
  margin: 10px;
  max-width: 100%;
}

.paperBox {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.unitEventIcon {
  color: #01a0b6;
  opacity: 0.8;
  font-size: 18px;
  margin: 10px 10px 10px 0;
}

.EditUnits {
  padding-bottom: 50px;
}

.EditUnits ol {
  list-style: none;
  counter-reset: item;
  padding: 0;
}

.EditUnits li {
  counter-increment: item;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EditUnits li:before {
  margin: 5px;
  content: counter(item);
  background: "#171717";
  height: 30px;
  width: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.sortableUnit > li > div > p {
  margin-left: 10px;
}

.unitEditContainer {
  border: 2px solid rgba(96, 96, 96, 0.6);
  border-radius: 30px;
  overflow: hidden;
  margin: 10px auto;
}

.unitOrderNum {
  background: rgba(96, 96, 96, 0.6);
  width: 40px;
  height: 60px;
}

.unitOrderNum > p {
  color: white;
  font-size: 20px;
  margin-left: 3px;
}

.unitNameField {
  width: 330px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.unitNameField:hover {
  font-weight: bold;
  cursor: pointer;
  color: #01a0b6;
}

.addUnitBtn {
  margin-left: 25px;
  color: #393939;
  opacity: 0.6;
  transition: 0.2s;
}

.addUnitBtn:hover {
  cursor: pointer;
  opacity: 0.9;
  transform: scale(1.1);
}

.addEventIcon {
  color: #393939;
  opacity: 0.7;
  transition: 0.05s;
  margin-right: 10px;
}

.addEventIcon:hover {
  cursor: pointer;
  opacity: 0.9;
  transform: scale(1.1);
}

.teachSubNameBox {
  border-radius: 30px;
  margin: 10px;
  padding-right: 15px;
}

.teachSubNameBox:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.teachSubNameBox p:hover {
  cursor: pointer;
}

.teachSubNameBox:active {
  transform: scale(0.95);
}

.allQuestionsContainer,
.subjectSyllabus {
  width: 95%;
  max-width: 680px;
}

#drawerContainer > div::-webkit-scrollbar {
  display: none;
  opacity: 0;
}

.studentMain {
  width: 100%;
  box-sizing: border-box;
}

.drawerFooter {
  position: absolute;
  bottom: 20px;
}

.profileIcon {
  height: 44px !important;
  width: 44px !important;
  opacity: 0.75;
}

.accountImgContainer {
  height: 44px;
  width: 44px;
  border-radius: 24px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.percentProgress {
  color: #393939;
  font-weight: bold;
  position: absolute;
  font-size: 20px;
}

.studentTabUnderBar {
  height: 5px;
  width: 33.3%;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  transition: 0.3s;
  right: 0;
}

.sBarL {
  transform: translateX(-200%);
  transition: 0.3s;
}

.sBarM {
  transform: translateX(-100%);
  transition: 0.3s;
}

.smClickableEvent {
  margin: 10px auto;
}

.smClickableEvent > img {
  width: auto;
  height: 30px;
}

.smAssignmentTitle:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Dashboard {
  top: 80px;
}

.dashSelect {
  padding: 3px 9px;
  border-radius: 15px;
  background-color: rgba(57, 57, 57, 0.1);
  margin: 0px 10px;
}

.dashSelect:hover {
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(57, 57, 57, 0.5);
}

.dashUpcomingEvents {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: auto;
  padding: 10px 0;
  min-width: 300px;
}

.dashUpcomingEvents .eventCard {
  margin: 15px;
}

.statsNum {
  font-size: 25px;
}

.statBox {
  margin: 20px auto;
  flex: 1;
}

.divider {
  width: 75%;
  margin-bottom: 6px !important;
}

.eDesc {
  margin: 5px auto;
}

.newsOpenIcon {
  color: white;
  size: 20px;
  opacity: 0.8;
}

.announcementTile {
  border-radius: 20px;
  height: 440px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.announceBox {
  background-color: #c45842;
  padding: 5px 10px;
  border-radius: 5px;
}

.shortcutCircle {
  height: 32px;
  width: 97px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin: 7px !important;
  transition: 0.2s;
  padding: 3px;
}

.shortcutCircle:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  transition: 0.2s;
}

.shortcutCircle:active {
  transform: scale(0.95);
  transition: 0.05s;
}

.shortcutCircle p:hover {
  cursor: pointer;
}

.beyondClassroom {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fiveable-92889.appspot.com/o/classroom-2093743_640.jpg?alt=media&token=cc1da412-374c-4c56-adf5-d56f97693c0c");
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.beyondClassroom > div {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  width: 100%;
}

.beyondTitle p {
  font-size: 38px;
  line-height: 30px;
  text-shadow: 2px 2px 5px #393939;
}

.beyondSubTitle > p {
  background-color: #393939;
  line-height: 18px;
}

.beyondBox > img {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 5px auto;
}

.specificEventNameBox {
  width: 100%;
  height: 75px;
  background: rgba(0, 0, 0, 0.2);
}

.quizLive,
.quizReplay {
  border-radius: 5px;
  padding: 2px 4px;
  margin-left: 8px;
}

.quizLive {
  background-color: #01a0b6;
}

.quizReplay {
  background-color: rgba(0, 0, 0, 0.3);
}

.quizLive > p,
.quizReplay > p {
  color: white;
  font-weight: bold;
  font-size: 13px;
}

.subscriptionPaper {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 792px;
  margin: 0 0 20px;
  padding: 20px;
}

.manageProfileIcon {
  height: 70px !important;
  width: 70px !important;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.manageProfileIcon > svg {
  height: 70px;
  width: 70px;
}

.updatePhoto {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 70px;
  width: 70px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.manageProfileIcon:hover .updatePhoto {
  visibility: visible;
  cursor: pointer;
}

.updatePhoto > p {
  color: white;
  text-shadow: 1px 1px 1px black;
  cursor: pointer;
}

.notification-settings-paper {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 792px;
  margin: 0 0 20px;
  padding: 20px;
}

.manageAccountIcon {
  padding: 5px;
  border-radius: 20px;
}

.shareContainer {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.socialShareDialog > div > div {
  margin: 0;
}

.accountEmailText {
  font-size: 20px;
}

.tagChip {
  margin: 5px;
  box-sizing: border-box;
}

.tagChipLoading {
  box-shadow: 0 0 6px #393939;
  animation-name: tagLoading;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.Subjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.subjectsHeader {
  margin: 20px auto;
}

.subjectsHeader > img {
  height: 80px;
  width: 80px;
  margin-right: 15px;
}

.subjectsHeader > p {
  font-size: 50px;
  color: white;
  font-weight: 400;
}

.subjectName {
  color: white !important;
  text-align: center;
  padding: 3px 10px 0;
  border-radius: 50px;
}

.subjectSpecificEventContainer {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  height: 94%;
  padding-top: 16px;
}

.subjectsOfFocusContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.otherSubjectImg {
  width: 60px;
  height: 60px;
  margin: 10px;
}

.purchaseButton {
  width: 100%;
  box-sizing: border-box;
  background-color: #f77118;
  margin-top: 16px;
  height: 45px;
  border-radius: 40px;
  border: none;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 95%;
  letter-spacing: 0;
}

.purchaseButton:hover {
  filter: brightness(90%);
  cursor: pointer;
}

.promoText {
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 5px;
}

.promoText:hover {
  cursor: pointer;
}

.purchaseDollar {
  vertical-align: super;
  font-size: 24px;
}

.testimonialContainer {
  height: 140px;
  margin-bottom: 30px;
}

.purchasingProgress {
  height: 25px !important;
  width: 25px !important;
  margin-right: 20px;
}

.logoPlus {
  height: 50px;
}

.plusLogo {
  font-size: 40px;
}

.productDisplayContainer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  padding: 15px;
  border-radius: 0 0 10px 10px;
}

.productPlan {
  margin: 10px;
  border-radius: 10px;
  max-width: 200px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.notSelectedPlan:hover {
  background-color: rgba(77, 185, 200, 0.5);
  cursor: pointer;
}

.notSelectedPlan:hover > p {
  cursor: pointer;
}

.selectedPlan {
  background-color: #01a0b6;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.featuresList {
  max-width: 400px;
}

.transactionBox {
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 5px;
  margin: 10px auto;
}

.teacherAppBarLogo {
  height: 50px;
  width: 50px;
  margin: 10px 10px 3px 0;
}

.selectedMenuItem {
  border-radius: 0 50px 50px 0 !important;
  width: 94% !important;
}

.studentListHeader {
  padding: 10px;
  min-height: 60px;
  width: 100%;
  max-width: 520px;
}

.studentListHeader > p {
  color: white;
  font-size: 1.25em;
}

.studentListBody {
  display: "flex";
  flex-direction: "column";
  align-items: "stretch";
  padding: 10px;
}

.studentBox {
  flex: 1;
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid lightgray;
  margin: 5px 0;
  background-color: white;
  box-sizing: border-box;
}

.studentBox:hover {
  box-shadow: 0 2px 5px lightgray;
}

.studentBox > div > .studentImage {
  height: 40px;
  width: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
}

.studentWarning {
  max-width: 225px;
  padding: 10px;
}

.couponSelect {
  background-color: #01a0b6;
  width: 100%;
  margin: 5px auto;
  padding: 5px 0;
  border-radius: 5px;
}

.couponSelect:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.couponSelect:active {
  transform: scale(0.96);
}

.couponSelect p:hover {
  cursor: pointer;
}

.courseColorBall {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 10px;
}

.coursesTable,
.coursesTable > tbody > tr > th,
.coursesTable > tbody > tr > td {
  /* border: 1px solid lightgray;
    border-collapse: collapse; */
  padding: 5px 10px;
}

.coursesTable > tbody > tr {
  border-bottom: 1px solid lightblue;
}

.coursesTable th {
  text-align: left;
  opacity: 0.8;
  padding-top: 10px;
  border-collapse: collapse;
}

.coursesTable td {
  height: 50px;
}

.coursesTable > tbody > tr > td:nth-child(2) {
  text-align: center;
  font-size: 25px;
  color: #01a0b6;
  font-weight: bold;
}

.selectSubjectBox {
  padding: 10px 20px 6px;
  transition: 0.3s;
}

.selectSubjectBox > p {
  font-size: 24px;
  transition: 0.3s;
}

.selectSubjectBox:hover {
  cursor: pointer;
  padding: 15px 25px 9px;
}

.selectSubjectBox:hover > p {
  cursor: pointer;
  font-size: 34px;
  text-shadow: 1px 1px 4px #393939;
}

.courseBox {
  border-radius: 20px;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  max-width: 360px;
}

.courseBoxTop {
  padding: 5px 0;
}

.courseBoxBottom {
  padding: 15px;
  border: 1px solid lightgray;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  box-shadow: inset 0 0 4px lightgray;
}

.courseBoxSubject {
  border-radius: 30px;
  padding: 0 6px 2px;
  margin-top: -4px;
}

.courseBoxSubject > p {
  font-size: 14px;
  margin: 0 12px;
}

.courseBoxSubject:hover {
  cursor: pointer;
  box-shadow: 0 3px 3px lightgray;
}

.courseBoxSubject:hover > p {
  cursor: pointer;
}

.slider-picker {
  width: 100%;
  max-width: 300px;
}

.colorSwab {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  margin-right: 15px;
}

.originLogo {
  height: "auto";
  width: 28px;
}

.courseTopText p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.courseTopText p:active,
.courseBoxSubject:active {
  transform: scale(0.95);
}

.courseTopText p:nth-child(1) {
  line-height: 18px;
  margin: 5px 0;
}

.tabUnder {
  height: 15px;
  position: relative;
  margin-bottom: 20px;
}

.tabUnderBar {
  height: 5px;
  width: 50%;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  transition: 0.3s;
  right: 0;
}

.barL {
  transform: translateX(-100%);
  transition: 0.3s;
}

.courseDetailsHeader {
  margin-bottom: 20px;
}

.nothingFoundImage {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin: 40px;
}

.enrollmentCodeBox {
  padding: 4px 14px;
  border-radius: 80px;
}

.enrollmentCodeBox p {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.formAssignmentDialog > div > div {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.formAssignmentDialog > div {
  width: 100%;
}

.formAssignmentDialog {
  width: 100%;
}

.createBtn {
  background-color: #01a0b6;
  padding: 5px 15px;
  border-radius: 30px;
  color: white;
}

.createBtn:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px gray;
}

.createBtn p:hover {
  cursor: pointer;
}

.createBtn:active {
  transform: scale(0.95);
}

.customDate {
  margin-top: 10px;
}

.customDate > p {
  margin-right: 10px;
  font-weight: 100;
  font-size: 0.9em;
}

.assignmentBox {
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin-bottom: 15px;
}

.assignmentBox:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.assignmentStateDisplay {
  color: white;
  font-weight: bold;
  padding: 1px 5px;
  border-radius: 20px;
  font-size: 0.7em;
  margin: 0 10px;
}

.assignmentBoxTitle:hover,
.assignmentIcon:hover {
  text-decoration: underline;
  cursor: pointer;
}

.assignmentBoxTitle {
  font-size: 2em;
  line-height: 1em;
}

.assignmentIcon {
  font-size: 50px !important;
}

.studentProgressBox {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 10px;
}

.eventBox {
  margin: 10px auto;
}

.eventBox > div {
  width: 100%;
}

.importToGoogleClassroom:hover,
.importToGoogleClassroom p:hover {
  cursor: pointer;
}

.importToGoogleClassroom > img {
  width: auto;
  height: 32px;
  margin-right: 15px;
}

.percentToPass {
  width: 42px;
  margin-left: 4px !important;
  margin-bottom: 15px !important;
}

.percentToPass > div > input {
  font-size: 20px;
  text-align: center;
  margin-bottom: -3px;
}

.classroomGrantBtn {
  border: 1px solid lightgray;
  padding: 3px 25px;
  border-radius: 30px;
  width: 275px;
}

.classroomGrantBtn:hover {
  cursor: pointer;
  /* transform: scale(1.025); */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.classroomGrantBtn p:hover {
  cursor: pointer;
}

.classroomGrantBtn img,
.courseSelectHeader img {
  height: 40px;
}

.courseSelect > div > div {
  width: 100%;
  max-width: 460px;
}

.loadingStudents > svg {
  color: rgba(0, 0, 0, 0.65) !important;
}

/* Make enrollment code text smaller on small screens */
@media only screen and (max-width: 400px) {
  .enrollmentCodeBox p {
    font-size: 0.75rem;
  }
}

.enrollmentCodeBox:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.enrollmentCodeBox:active {
  transform: scale(0.96);
}

.enrollmentCodeBox p:hover {
  cursor: pointer;
}

.gcOrigin {
  margin-left: 10px;
  margin-top: 3px;
}

.gcOrigin > img {
  height: 20px;
  width: auto;
}

.courseBoxTopTitle {
  line-height: 20px;
}

.eventCreateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.teacherEventsForm,
.eventView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 15px;
  margin: 0 auto 25px;
  max-width: 700;
  width: 96%;
}

.eventViewContainer {
  padding-bottom: 50px;
}

.eventIcon {
  color: #393939;
  opacity: 0.7;
  transition: 0.15s;
  margin-right: 10px;
}

.eventTypeAndDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.eventTypeAndDate p {
  opacity: 1;
}

.eventDescription p:nth-child(1) {
  margin-bottom: 5px;
}

.eventDescription p:nth-child(2) {
  margin-bottom: 15px;
}

.topicsHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
}

.topicChip {
  margin: 3px;
}

.topicSelect {
  margin: 3px;
  font-weight: 400;
  transition: 0.15s;
  color: #393939;
}

.topicSelect:hover {
  cursor: pointer;
  color: #4db9c8;
}

.newTopicInput {
  max-width: 100%;
  width: 300px;
}

.availableTopics {
  border-right: 1px solid rgba(96, 96, 96, 0.3);
}

.topicsContainer {
  margin: 10px auto;
}

.iconButton {
  margin: 6px;
}

.questionViewAnswers {
  width: 570px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.answer {
  margin: 10px;
}

.correct {
  color: #4db9c8;
  font-weight: bold;
}

.questionBtns {
  align-self: flex-end;
}

.questionsContainer {
  max-width: 85%;
  width: 615px;
  margin-bottom: 40px;
}

.questionsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.sortableQuestion {
  padding: 15px;
  background: lightgray;
  border-radius: 35px;
  margin: 5px;
  display: flex;
  flex-direction: row;
}

.EditQuestionOrder {
  margin-top: 25px;
}

.EditQuestionOrder ol {
  list-style: none;
  counter-reset: item;
  margin-right: 40px;
}

.EditQuestionOrder li {
  counter-increment: item;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EditQuestionOrder li:before {
  margin-right: 10px;
  content: counter(item);
  background: #4db9c8;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ccImg {
  border: 8px solid gray;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.questionFormContainer {
  margin-top: 100px;
}

.endSessionBtnBox {
  margin: 15px;
}

.endSessionBtn {
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #c45842;
  opacity: 0.7;
  margin-left: 10px;
}

.eventClosed {
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px 6px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: #c45842;
  opacity: 0.7;
}

.eventClosed:hover {
  content: attr("hdhd");
}

.endSessionBtn:hover {
  box-shadow: 1px 1px 10px #393939;
  transform: scale(1.05);
  cursor: pointer;
  opacity: 0.9;
}

.reopenEvent {
  border-radius: 5px;
  padding: 3px 6px;
  font-weight: bold;
  font-size: 12px;
  color: white;
  background-color: #01a0b6;
  opacity: 0.6;
}

.reopenEvent:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.chalkMessage > img {
  width: 94%;
}

.trfSubHead {
  align-self: flex-start;
}

.resourceType {
  width: 250px;
  margin-top: 8px !important;
}

.oldQuestionContainer {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
}

.oldQuestionOptions {
  min-width: 500px;
}

.clearScoresBtn {
  padding: 5px;
  border-radius: 5px;
  background-color: #393939;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.streamerBox:hover {
  cursor: pointer;
  background-color: #01a0b6;
}

.streamerBox:hover p {
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 959px) {
  .loadingPlaceholder {
    top: 52px;
  }
  .Dashboard {
    top: 65px;
  }
}

@media only screen and (max-height: 650px) {
  .drawerFooter {
    margin: 40px auto 10px;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  .sm {
    font-size: 11px !important;
  }

  .md {
    font-size: 12px !important;
  }

  .lg {
    font-size: 14px !important;
  }

  .xl {
    font-size: 17px !important;
  }

  .xxl {
    font-size: 20px !important;
  }

  .xxxl {
    font-size: 23px !important;
  }

  .extra-info-form {
    box-shadow: none !important;
  }

  .pilotBlueCentralLogo {
    width: 70%;
  }

  .pilotFormPopUp > div > div {
    margin: 0 !important;
  }

  .testimonialsContainer {
    padding: 10px 0;
  }

  .testimonialContainer img {
    border-radius: 30px;
    height: 60px;
    width: 60px;
  }

  .exitPopUp > div > div {
    margin: 0;
  }

  .ssBody {
    margin-top: -100px;
  }

  .ssHero {
    align-items: center;
  }
  .ssHero > h1 {
    font-size: 1.5rem;
  }

  .salesHeader {
    font-size: 24px;
    line-height: 20px;
  }

  .salesSubheader {
    font-size: 14px;
  }

  .salesLogo {
    height: auto;
    width: 85px;
  }

  .nav-logo {
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }
  .teachNavHeader {
    font-size: 8px;
    margin: 0 3px;
  }
  .nav-container {
    width: 40px;
  }
  .BodyContainer {
    margin-left: 40px;
  }
  .unitEventIcon {
    margin: 10px 10px 0 0;
  }
  .unitNumBox {
    margin-left: 0;
  }
  .unitEventPaper {
    width: 280px;
  }
  .studentMain {
    padding: 5px !important;
  }

  .profileIcon {
    height: 34px !important;
    width: 34px !important;
  }

  .subjectsHeader > img {
    height: 50px;
    width: 50px;
    margin-right: 10px;
  }
  .subjectsHeader > p {
    font-size: 35px;
  }
  .nextLastContainer {
    padding: 0;
  }
  .nextLastContainer > div > a {
    width: 100%;
  }
  .meetTeacherBox {
    width: 96%;
  }
  .assignmentBoxTitle {
    font-size: 1.4em;
  }

  .assignmentIcon {
    font-size: 36px !important;
  }
}

@media only screen and (max-width: 477px) {
  .questionFormPaper {
    padding: 5px;
  }
}

@-moz-document url-prefix() {
  .replayContentList {
    position: absolute;
    height: 75%;
  }
}

@media only screen and (max-width: 793px) {
  .subscriptionPaper {
    width: 100%;
  }
}

@media only screen and (max-width: 499px) {
  .manageProfileIcon {
    height: 40px !important;
    width: 40px !important;
    border-radius: 20px;
  }
  .manageProfileIcon > svg {
    height: 40px !important;
    width: 40px !important;
  }
  .accountEmailText {
    font-size: 12px;
  }
  .Products {
    /* padding-top: 50px; */
    padding: 10px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes tagLoading {
  from {
    box-shadow: 0 0 6px #393939;
  }
  to {
    box-shadow: 0 0 3px #393939;
  }
}

/* ======================================== *
              BEST VIEWED FULLSCREEN
     https://codepen.io/ig_design/full/NeRxzj
   * ======================================== */

.campfire {
  position: relative;
  z-index: 39;
  width: 2px;
  margin-left: -1px;
  left: 77%;
  bottom: 90px;
  -webkit-transition: all 1200ms linear;
  transition: all 1200ms linear;
}
.campfire span {
  display: block;
  position: absolute;
  bottom: -25px;
  margin-left: -20px;
  height: 0px;
  width: 0px;
  border: 15px solid #febd08;
  border-radius: 50%;
  border-top-left-radius: 0;
  left: -9px;
  box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.8), 0 0 20px 10px rgba(244, 110, 28, 0.6),
    0 0 30px 15px rgba(244, 110, 28, 0.3);
  transform: scale(0.45, 0.75) rotate(45deg);
  animation: fireFlicker 2.5s alternate infinite;
  z-index: 9;
  -webkit-transition: all 1200ms linear;
  transition: all 1200ms linear;
}
.campfire span:after {
  display: block;
  bottom: -7.5px;
  content: "";
  margin-left: -3px;
  height: 15px;
  width: 6px;
  background-color: rgba(244, 110, 28, 0.7);
  border-radius: 80px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 10px 5px rgba(244, 110, 28, 0.7);
  left: -9px;
  opacity: 0.8;
  transform: rotate(-50deg);
}
.campfire span:nth-child(2) {
  left: -18px;
  transform: scale(0.3, 0.55) rotate(15deg);
  z-index: 8;
  animation: fireFlicker 1.5s alternate infinite;
}
.campfire span:nth-child(3) {
  left: 2px;
  transform: scale(0.3, 0.55) rotate(80deg);
  z-index: 8;
  animation: fireFlicker 2s alternate infinite;
}
@keyframes fireFlicker {
  0%,
  100% {
    box-shadow: 0 0 5px 1px rgba(244, 110, 28, 0.8), 0 0 10px 5px rgba(244, 110, 28, 0.6),
      0 0 15px 7.5px rgba(244, 110, 28, 0.3);
  }
  50% {
    box-shadow: 0 0 7px 3.5px rgba(244, 110, 28, 0.8), 0 0 14px 7px rgba(244, 110, 28, 0.6),
      0 0 21px 10.5px rgba(244, 110, 28, 0.3);
  }
}
.campfireSmoke {
  position: relative;
  z-index: 40;
  width: 2px;
  margin-left: -1px;
  left: 70.25%;
  bottom: 85px;
  opacity: 0;
  -webkit-transition: all 800ms linear;
  transition: all 800ms linear;
}
.campfireSmoke span {
  display: block;
  position: absolute;
  bottom: -35px;
  left: 50%;
  margin-left: -20px;
  height: 0px;
  width: 0px;
  border: 30px solid rgba(0, 0, 0, 0.6);
  border-radius: 22px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  left: -9px;
  opacity: 0;
  transform: scale(0.2, 0.2) rotate(-45deg);
}
@keyframes smokeLeft {
  0% {
    transform: scale(0.2, 0.2) translate(0, 0) rotate(-45deg);
  }
  10% {
    opacity: 1;
    transform: scale(0.2, 0.3) translate(0, -5px) rotate(-45deg);
  }
  60% {
    opacity: 0.6;
    transform: scale(0.3, 0.5) translate(-10px, -80px) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.4, 0.8) translate(-20px, -120px) rotate(-45deg);
  }
}
@keyframes smokeRight {
  0% {
    transform: scale(0.2, 0.2) translate(0, 0) rotate(-45deg);
  }
  10% {
    opacity: 1;
    transform: scale(0.2, 0.3) translate(0, -5px) rotate(-45deg);
  }
  60% {
    opacity: 0.6;
    transform: scale(0.3, 0.5) translate(10px, -80px) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.4, 0.8) translate(20px, -120px) rotate(-45deg);
  }
}
.campfireSmoke .smoke0 {
  animation: smokeLeft 7s 0s infinite;
}
.campfireSmoke .smoke1 {
  animation: smokeRight 7s 0.7s infinite;
}
.campfireSmoke .smoke2 {
  animation: smokeLeft 7s 1.4s infinite;
}
.campfireSmoke .smoke3 {
  animation: smokeRight 7s 2.1s infinite;
}
.campfireSmoke .smoke4 {
  animation: smokeLeft 7s 2.8s infinite;
}
.campfireSmoke .smoke5 {
  animation: smokeRight 7s 3.5s infinite;
}
.campfireSmoke .smoke6 {
  animation: smokeLeft 7s 4.2s infinite;
}
.campfireSmoke .smoke7 {
  animation: smokeRight 7s 4.9s infinite;
}
.campfireSmoke .smoke8 {
  animation: smokeLeft 7s 5.6s infinite;
}
.campfireSmoke .smoke9 {
  animation: smokeRight 7s 6.3s infinite;
}

/* #Switched
  ================================================== */

.campfireOff .campfireOn {
  opacity: 0;
}
.campfireOff .campfireSmoke {
  opacity: 1;
  transition-delay: 0.6s;
}
.campfireOff .campfire span {
  bottom: -35px;
  left: -9px;
  transform: scale(0.15, 0.15) rotate(45deg);
}

.acsb-trigger {
  bottom: 35px !important;
  right: 105px !important;
  z-index: 2147483647 !important; /* one less than Drift's */
}

/* Draft Editor
  ================================================== */
.public-DraftEditorPlaceholder-root {
  min-width: 350px;
}

.public-DraftEditor-content div figure img {
  margin: 0 !important;
  max-width: 500px !important;
  width: auto !important;
}

.public-DraftEditor-content div figure {
  margin: 0 !important;
}

.library-term-base-text {
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.library-term-base-text:hover {
  color: rgba(0, 0, 0, 0.87);
}

.library-term-non-link-text {
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  cursor: default;
}
