.link-preview {
  & h3 {
    font-size: medium;
  }

  & .LowerContainer {
    border-top: 1px solid lightgray;
  }
}

.resource-dropdown {
  & button {
    & svg {
      /** black magic */
      color: white;
      mix-blend-mode: difference;
      transform: rotate(90deg);
    }
  }
}
