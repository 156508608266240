// AGE VERIFICATION
.age-verification {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

.typeform-embed {
  height: 1200px;
}

.typeform-embed iframe {
  border: 1px solid red;
  height: 1200px !important;
}

// NAVBAR STYLES
.navbar {
  background-color: white;

  &-nav {
    align-items: center;

    .dropdown-toggle::after {
      display: none;
    }
  }
}

.textOverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 100%;
}

@media (max-width: 768px) {
  .navbar-nav {
    align-items: flex-start;

    .button-dark {
      display: inline-block;
      margin: 5px 0;
    }
  }

  .nav-sign-up {
    display: inline-block;
  }

  #nav-sign-up-link {
    padding: 10px 5px;
    font-size: 0.9rem;
  }
}

@keyframes pepSleepingAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.floatingPepper {
  animation: pepAnimation 2s ease-in-out infinite;
}

@keyframes pepAnimation {
  0%,
  100% {
    transform: translateY(-16px);
  }
  50% {
    transform: translateY(0);
  }
}

// BOOTSTRAP DROPDOWN
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.styled-dropdown-menu {
  padding: 0;
  border-radius: 4px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  .dropdown-item {
    padding: 10px 20px;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: #eee;
    }

    &:active {
      background-color: #ddd;
      color: black;
    }

    & span {
      display: inline-block;
      font-size: 20px;
      transform: translateY(2px);
      padding-right: 5px;
    }
  }

  .dropdown-divider {
    margin: 5px 0;
  }
}

// CATALYST VIDEO
.session-section {
  #catalyst {
    .ct-body {
      background-color: #ddd;
      border-radius: 4px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .ct-btn-container {
      margin: 0 7.5px;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  #remote-video {
    border-radius: 4px !important;
  }

  .ct-multi-btn {
    border-radius: 4px !important;
  }

  & svg[data-icon="microphone"] {
    width: 14px !important;
  }

  & svg[data-icon="microphone-slash"] {
    width: 25px !important;
  }

  & svg[data-icon="video"] {
    width: 22px !important;
  }

  & svg[data-icon="video-slash"] {
    width: 24px !important;
  }

  & svg[data-icon="expand"] {
    width: 18px !important;
  }

  & svg[data-icon="desktop"] {
    width: 20px !important;
  }

  & svg[data-icon="phone-slash"] {
    width: 19px !important;
  }

  .ct-tooltip span {
    border-radius: 4px !important;
  }

  #catalyst {
    #local-video {
      border-radius: 4px !important;
      border-width: 2px;
    }

    #ct-local-text {
      font-size: 13px;
    }
  }
}

.session-section-dark {
  #catalyst.ct-body {
    background-color: $dark-mode-dark;
  }

  .ct-multi-btn {
    background-color: $dark-mode-light !important;

    & * {
      color: white;
    }
  }
}

// DAILY VIDEO
#dailyframe-container {
  display: none;
}

// REACT SKELETON
.css-1122c0p-SkeletonTheme {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.participants,
.chat,
.tasks {
  .css-1122c0p-SkeletonTheme {
    align-items: stretch !important;
  }
}

.modal-body a {
  color: #01a0b6;
}

// INTROJS
.introjs-tooltipbuttons {
  display: block !important;
  border-top: none !important;
}

// AUTH MODALS
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  overflow: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 200;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media (max-width: 800px) {
  .ReactModal__Content {
    width: 95% !important;
    grid-template-columns: none !important;
  }
}

// MUSIC PLAYER
.audio-main {
  padding: 0;

  .progress-bar {
    background-color: transparent;
  }
}

.music-player-panel {
  z-index: 1 !important;
}

// HELP MODALS
.step-obj {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;

  .user-badge {
    margin-right: 10px;
  }

  p {
    font-size: 13px;
    color: #777;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

// SIGNUP MODAL
.signup-perks {
  list-style: none;
  padding-left: 10px;

  & li {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;

    & svg {
      width: 100%;
      color: $color-primary;
    }
  }
}

// DASHBOARD SURVEY
.dashboard-survey .modal-header .close {
  display: none;
}

.survey-progressbar .progress-bar {
  background-color: $color-primary;
}

// INTROJS
.introjs-tooltipbuttons {
  display: none;
}

.introjs-tooltiptext {
  padding: 10px;
}

// PUFFLOADERS
.css-1d9ls5a {
  margin: 20px auto;
}

.invite {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 5px;
}

.label-divider {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: #ccc;
  margin: 15px 0;
  font-size: 15px;

  &:before,
  &:after {
    content: "";
    flex-grow: 1;
    background: #ccc;
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
}

// MUI SELECT
.MuiMenu-root .MuiMenu-list {
  max-height: 23rem;
}

.excellentIcon {
  filter: drop-shadow(1px 1px 1px black);
}
