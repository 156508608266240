$red-light: #da655a;
$red: #db4c3f;
$red-dark: #b53d32;

$lightblue-light: #6ec8e4;
$lightblue: #4dc1e6;
$lightblue-dark: #409fbe;

$blue-light: #5184e8;
$blue: #316fea;
$blue-dark: #2b5ec3;

$darkblue-light: #2d68c1;
$darkblue: #175bc2;
$darkblue-dark: #13499a;

$orange-light: #f69d2b;
$orange: #ff9001;
$orange-dark: #d57d0b;

$yellow-light: #f9d127;
$yellow: #fecf03;
$yellow-dark: #ddb508;

$purple-light: #933caa;
$purple: #8e24aa;
$purple-dark: #6d1b82;

$pink-light: #e9aad0;
$pink: #eb96c9;
$pink-dark: #d564a8;

$grey-light: #636f79;
$grey: #506678;
$grey-dark: #3c4c59;

.session-theme-red {
  .theme-icon {
    color: $red;
  }
  .button-dark {
    background-color: $red;
    border-color: $red;

    &:hover {
      background-color: $red-dark;
      border-color: $red-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $red !important;
  }

  .profile-picture {
    border-color: $red;
  }
}

.session-theme-lightblue {
  .theme-icon {
    color: $lightblue;
  }
  .button-dark {
    background-color: $lightblue;
    border-color: $lightblue;

    &:hover {
      background-color: $lightblue-dark;
      border-color: $lightblue-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $lightblue !important;
  }

  .profile-picture {
    border-color: $lightblue;
  }
}

.session-theme-blue {
  .theme-icon {
    color: $blue;
  }
  .button-dark {
    background-color: $blue;
    border-color: $blue;

    &:hover {
      background-color: $blue-dark;
      border-color: $blue-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $blue !important;
  }

  .profile-picture {
    border-color: $blue;
  }
}

.session-theme-darkblue {
  .theme-icon {
    color: $darkblue;
  }
  .button-dark {
    background-color: $darkblue;
    border-color: $darkblue;

    &:hover {
      background-color: $darkblue-dark;
      border-color: $darkblue-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $darkblue !important;
  }

  .profile-picture {
    border-color: $darkblue;
  }
}

.session-theme-orange {
  .theme-icon {
    color: $orange;
  }
  .button-dark {
    background-color: $orange;
    border-color: $orange;

    &:hover {
      background-color: $orange-dark;
      border-color: $orange-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $orange !important;
  }

  .profile-picture {
    border-color: $orange;
  }
}

.session-theme-yellow {
  .theme-icon {
    color: $yellow;
  }
  .button-dark {
    background-color: $yellow;
    border-color: $yellow;

    &:hover {
      background-color: $yellow-dark;
      border-color: $yellow-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $yellow !important;
  }

  .profile-picture {
    border-color: $yellow;
  }
}

.session-theme-purple {
  .theme-icon {
    color: $purple;
  }
  .button-dark {
    background-color: $purple;
    border-color: $purple;

    &:hover {
      background-color: $purple-dark;
      border-color: $purple-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $purple !important;
  }

  .profile-picture {
    border-color: $purple;
  }
}

.session-theme-pink {
  .theme-icon {
    color: $pink;
  }
  .button-dark {
    background-color: $pink;
    border-color: $pink;

    &:hover {
      background-color: $pink-dark;
      border-color: $pink-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $pink !important;
  }

  .profile-picture {
    border-color: $pink;
  }
}

.session-theme-grey {
  .theme-icon {
    color: $grey;
  }
  .button-dark {
    background-color: $grey;
    border-color: $grey;

    &:hover {
      background-color: $grey-dark;
      border-color: $grey-dark;
    }
  }

  .user-badge,
  .task-card-header,
  .current-tag {
    background-color: $grey !important;
  }

  .profile-picture {
    border-color: $grey;
  }
}
