.participants {
  .participants-body {
    height: 100%;
    padding-bottom: 40px;
  }
}

.participants-list {
  height: 100%;
  list-style: none;
  padding-left: 0;
  overflow-y: auto;
}

.participants-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .request-icon {
    margin: 0 10px;
    width: 20px;
    color: #aaa;
    // transform: translateY(-2px);
    cursor: pointer;
  }

  .participants-clickable:hover {
    text-decoration: underline;
    text-decoration-color: #aaa;
    cursor: pointer;
  }
}

.profile-modal {
  .profile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .profile-info {
      display: grid;
      grid-template-columns: 50px 1fr;
      align-items: center;
      grid-gap: 15px;

      & img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      & p {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  .profile-body {
    padding-top: 30px;
    overflow: auto;

    .mutual-friends {
      .mutual-friend {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 7.5px 0;

        & img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        & span {
          color: #555;
          margin-left: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }

    .mutual-sessions {
      .mutual-session {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 7.5px 0;

        & span {
          color: #555;
          margin-left: 10px;
        }

        & a {
          color: #555;
          text-decoration: underline;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

.session-section-dark {
  .participants-item {
    &:not(:last-child) {
      border-bottom: 1px solid $dark-mode-lighter;
    }

    .request-icon path {
      color: #afa9a9 !important;
    }
  }
}

.action-menu {
  .menu-icon {
    color: #aaa;
    height: 15px;
  }
}
