.settings-display {
  & > * {
    margin-bottom: 15px;
  }
}

.settings-section {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.picture-upload-container {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 15px;
  align-items: center;

  & img {
    width: 100%;
    border-radius: 50%;
  }
}
