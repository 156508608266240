.ended-grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 15px;
}

.ended-section {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 15px;
}

.participants-item-hover {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
}

.participants-item-active {
  background-color: #eee;
  border-radius: 4px;
}

.statistics {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.statistics-grid {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 7.5px));
  grid-gap: 15px;
  align-items: flex-start;
}

.ending-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & a {
    width: auto;
    margin-right: 5px;
  }

  .ending-button {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    & svg {
      width: 25px;
      margin-right: 15px;
    }
  }
}

.share-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .share-button {
    width: 100%;
    text-align: center;
    border-radius: 0;

    & svg {
      width: 25px;
    }
  }
}

.twitter {
  background-color: #1da1f2 !important;
  border-color: #1da1f2 !important;

  &:hover {
    background-color: #198dd4 !important;
    border-color: #198dd4 !important;
  }
}

.reddit {
  background-color: #ff5700 !important;
  border-color: #ff5700 !important;

  &:hover {
    background-color: #ff4500 !important;
    border-color: #ff4500 !important;
  }
}

.facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;

  &:hover {
    background-color: #2e4575 !important;
    border-color: #2e4575 !important;
  }
}

.linkedin {
  background-color: #0e76a8 !important;
  border-color: #0e76a8 !important;

  &:hover {
    background-color: #0b618a !important;
    border-color: #0b618a !important;
  }
}

.feedback {
  background-color: #fc5a5a !important;
  border-color: #fc5a5a !important;

  &:hover {
    background-color: #cf4a4a !important;
    border-color: #cf4a4a !important;
  }
}

@media (max-width: 1000px) {
  .ended-grid {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

@media (max-width: 768px) {
  .statistics-grid {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
