//---------------------QUILL DARK MODE STYLE OVERRIDES-----------------------------------
.customQuill-dark .ql-snow .ql-stroke {
  stroke: white;
}

.customQuill-dark .ql-snow .ql-fill {
  stroke: #b5b5b5;
}

//make formatting buttons green in dark mode
.customQuill-dark .ql-formats button:hover .ql-stroke {
  stroke: $color-primary !important;
}
//make active formatting buttons green in dark mode
.customQuill-dark .ql-formats .ql-active .ql-stroke {
  stroke: $color-primary !important;
}

//make formatting buttons green in dark mode
.customQuill-dark .ql-formats button:hover .ql-fill {
  stroke: $color-primary !important;
}

//make active formatting buttons green in dark mode
.customQuill-dark .ql-formats .ql-active .ql-fill {
  stroke: $color-primary !important;
}

//make formatting select arrows green in dark mode
.customQuill-dark .ql-formats span:hover .ql-picker-label .ql-stroke {
  stroke: $color-primary !important;
  fill: $color-primary !important;
  color: $color-primary !important;
}

//make active formatting selects green in dark mode
.customQuill-dark .ql-formats span:focus .ql-picker-label .ql-stroke {
  stroke: $color-primary !important;
  fill: $color-primary !important;
  color: $color-primary !important;
}

//make formatting select labels green in dark mode
.customQuill-dark .ql-formats span:hover .ql-picker-label {
  stroke: $color-primary !important;
  fill: $color-primary !important;
  color: $color-primary !important;
}

//make active formatting select labels green in dark mode
.customQuill-dark .ql-formats .ql-picker-label.ql-active {
  stroke: $color-primary !important;
  fill: $color-primary !important;
  color: $color-primary !important;
}

//select drop down
.customQuill-dark span.ql-picker-options {
  background: #252429;
  color: white;
}

.customQuill-dark span.ql-picker-options .ql-picker-item:hover {
  color: $color-primary;
}

.customQuill-dark span.ql-picker-options .ql-picker-item.ql-selected {
  color: $color-primary;
}

//-----------------END QUILL DARK MODE STYLE OVERRIDES-----------------------------------
//---------------------QUILL LIGHT MODE STYLE OVERRIDES----------------------------------

//make formatting buttons green in light mode
.customQuill .ql-formats button:hover .ql-stroke {
  stroke: $color-primary-dark !important;
}
//make active formatting buttons green in light mode
.customQuill .ql-formats .ql-active .ql-stroke {
  stroke: $color-primary-dark !important;
}

//make formatting buttons green in light mode
.customQuill .ql-formats button:hover .ql-fill {
  fill: $color-primary-dark !important;
}

//make active formatting buttons green in light mode
.customQuill .ql-formats .ql-active .ql-fill {
  fill: $color-primary-dark !important;
}

//make formatting select arrows green in light mode
.customQuill .ql-formats span:hover .ql-picker-label .ql-stroke {
  stroke: $color-primary-dark !important;
  color: $color-primary-dark !important;
}

//make active formatting selects green in light mode
.customQuill .ql-formats span:focus .ql-picker-label .ql-stroke {
  stroke: $color-primary-dark !important;
  fill: $color-primary-dark !important;
  color: $color-primary-dark !important;
}

//make formatting select labels green in light mode
.customQuill .ql-formats span:hover .ql-picker-label {
  stroke: $color-primary-dark !important;
  fill: $color-primary-dark !important;
  color: $color-primary-dark !important;
}

//make active formatting select labels green in light mode
.customQuill .ql-formats .ql-picker-label.ql-active {
  stroke: $color-primary-dark !important;
  fill: $color-primary-dark !important;
  color: $color-primary-dark !important;
}

//select drop down
.customQuill span.ql-picker-options {
  background: white;
}

.customQuill span.ql-picker-options .ql-picker-item:hover {
  color: $color-primary-dark;
}

.customQuill span.ql-picker-options .ql-picker-item.ql-selected {
  color: $color-primary-dark;
}

//-----------------END QUILL LIGHT MODE STYLE OVERRIDES---------------------------------

.ql-editor {
  min-height: 500px !important;
}
