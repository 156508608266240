.input-group {
  margin-top: 15px;

  & label {
    color: #303030;
  }

  & input:not([type="checkbox"]) {
    display: block;
    width: 100%;
  }

  & input[type="checkbox"] {
    margin-right: 10px;
  }

  &.flex {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.input-dark {
  background-color: white;
  padding: 7.5px 12.5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: $color-primary;
  }

  &-dark {
    background-color: $dark-mode-dark;
    border: 1px solid $dark-mode-dark;
    padding: 7.5px 12.5px;
    border-radius: 4px;
    transition: all 0.2s;
    color: white;

    &:focus {
      outline: none;
      border-color: white;
    }
  }
}

.input-success {
  color: $color-primary-dark;
}

.input-auto-fill {
  background-color: rgba($color-primary-light, 0.4) !important;
}

.disabled {
  background-color: #ddd;
}

.dark-disabled {
  background-color: #333;
}

.error {
  width: 100%;
  color: red;
}
