.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .options-grid {
    display: flex;
    flex-direction: row;
    align-items: center;

    .toggle-align {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;

      & label {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }

  .header-settings-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    .setting {
      background-color: #eee;
      padding: 5px 7.5px;
      border-radius: 4px;
      transition: all 0.2s;
      cursor: pointer;
      transform: translateY(-5px);

      &:hover {
        background-color: #ccc;
      }

      & svg {
        color: #333;
      }

      &-dark {
        background-color: $dark-mode-dark;
        padding: 5px 7.5px;
        border-radius: 4px;
        transition: all 0.2s;
        cursor: pointer;
        transform: translateY(-5px);
      }
    }

    .active svg {
      background-image: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 1.5px),
        #333 50%,
        rgba(0, 0, 0, 0) calc(50% + 1.5px),
        rgba(0, 0, 0, 0) 100%
      );
    }

    .active-dark svg {
      background-image: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 1.5px),
        white 50%,
        rgba(0, 0, 0, 0) calc(50% + 1.5px),
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .buttons-grid {
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-gap: 5px;
    width: 100%;

    .invite-link {
      display: grid;
      grid-template-columns: 1fr 50px;
      grid-gap: 5px;
      align-items: center;

      & input {
        width: 100%;
      }
    }

    .buttons {
      margin-left: auto;
    }
  }
}

@media (max-width: 1100px) {
  .buttons-grid {
    grid-template-columns: repeat(auto-fill, 100%) !important;

    .buttons {
      margin: auto;
    }
  }
}
