.emoji-mart {
  &-button {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    border-radius: 50%;
    padding: 5px 7px;
    color: #aaa;
    background-color: transparent;
    border: none;
    transition: all 0.2s;

    &:hover {
      background-color: #eee;
    }

    &:focus {
      outline: none;
    }

    &-dark:hover {
      background-color: $dark-mode-light;
    }
  }
}

.chat {
  position: relative;
  padding-bottom: 19px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .chat-body {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .empty-chat {
      position: absolute;
      top: 45%;
      transform: translateY(-50%);

      text-align: center;
      width: calc(100% - 30px);
      margin: 0 auto;

      & img {
        max-width: 200px;
        margin-bottom: 10px;
      }

      & span {
        display: block;
        color: #707070;
      }
    }

    .chat-list {
      height: 100%;
      list-style: none;
      padding-left: 0;
      overflow-y: auto;
      width: 100%;
      word-break: break-word;
      margin-bottom: 0;
      padding-bottom: 1em;
    }

    .chat-item {
      word-break: break-word;

      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 10px;

      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 2.5px;

      &:first-child {
        margin-top: 0;
      }

      &:not(:first-child) {
        border-top: 1px solid #eee;
      }

      .sender-info {
        display: flex;
        width: 100%;
        justify-content: space-between;

        > p {
          flex-grow: 1;
        }
      }

      &-system {
        display: block;
        text-align: center;
        padding: 7.5px 0;
        font-size: 13px;
        color: rgb(170, 170, 170);
      }

      &-spliced {
        border-top: none !important;
        padding: 0;
        margin-top: 0;
        margin-bottom: 2.5px;
      }
    }
  }
}

.chat-wrapper {
  position: relative;
  border-radius: 4px;

  .message-deleted {
    color: #bdbdbd;
    font-style: italic;
  }

  .chat-option .hide {
    display: none;
  }

  &.chat-wrapper-highlighted {
    background-color: #fafafa;
    &.dark {
      background-color: $dark-mode-dark;
    }
  }

  &.dark {
    background-color: transparent;
  }
}

.chat-option {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  .dropdown-chat-button {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    font-size: 25px;
    color: #dddddd;
    padding: 0 12.5px;
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out;

    &:focus,
    &:hover {
      outline: 0;
      background-color: #f5f5f5;
      &.dark {
        background-color: $dark-mode-dark;
      }
    }

    &:active {
      background-color: #f5f5f5;
      &.dark {
        background-color: $dark-mode-dark;
      }
    }

    &.dark {
      background-color: $dark-mode-light;
      border-color: $dark-mode-lighter;
    }
  }

  .dropdown-chat-anchor {
    position: absolute;
    left: 40%;
    top: 40%;
  }

  &.hide {
    display: none;
  }
}

.hide {
  display: none;
}

.chat-wrapper-highlighted {
  background-color: #fafafa;
}

.dropdown-chat-wrapper {
  padding: 0.5rem 0;

  .hours-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-decoration: none;

    &:hover {
      background-color: #eee;
    }
  }
}

.session-section-dark {
  .chat-item {
    border-color: $dark-mode-lighter !important;
  }

  .emoji-mart * {
    color: unset;
  }
}

.emoji-picker-popover-container {
  margin: 0;
  z-index: 999;
}

.emoji-picker-container {
  section {
    padding: 0;
  }
}
